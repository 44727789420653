import { RemoteConfig } from '../../remote-config/config-type';

import styles from './footer.module.scss';

interface Props {
    remoteConfig?: RemoteConfig;
}

const ContactInfo = ({ remoteConfig }: Props) => (
    <section className={styles.contactInfo}>
        <p className={styles.copyright}>
            © 2024 Cappacho. All rights reserved.
        </p>
        <div className={styles.contactBlock}>
            <label>Address</label>
            <p>{remoteConfig?.landingPageContactInfo.address}</p>
        </div>
        <div className={styles.contactBlock}>
            <label>Contact email</label>
            <p>{remoteConfig?.landingPageContactInfo.email}</p>
        </div>
        <div className={styles.contactBlock}>
            <label>Contact number</label>
            <p>{remoteConfig?.landingPageContactInfo.phoneNumber}</p>
        </div>
    </section>
);
export default ContactInfo;
