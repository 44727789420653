import { useRemoteConfig } from '../../hooks/remote-config';

import styles from './footer.module.scss';
import DestinationLinks from './destination-links';
import ContactInfo from './contact-info';
import { EarthIcon } from 'lucide-react';

const Footer = () => {
    const remoteConfig = useRemoteConfig();

    return (
        <footer className={styles.footer}>
            <div className={styles.content}>
                <span className={styles.divider} />
                <span className={styles.intl}>
                    <EarthIcon color="white" size={20} />
                    English
                </span>
                <span className={styles.divider} />
                <div className={styles.columns}>
                    <ContactInfo remoteConfig={remoteConfig} />
                    <DestinationLinks />
                </div>
            </div>
        </footer>
    );
};

export default Footer;
