import LuisImage from '../../../../assets/img/luis.webp';
import RebeImage from '../../../../assets/img/rebe.webp';
import GuttyImage from '../../../../assets/img/gutty.webp';

import styles from './our-team.module.scss';

const OurTeam = () => {
    return (
        <section className={styles.ourTeam}>
            <h2>Our team</h2>
            <ul className={styles.memberList}>
                <li className={styles.memberItem}>
                    <div className={styles.memberImageContainer}>
                        <img src={LuisImage} alt="ceo-picture" />
                    </div>
                    <b>Luis Hobaica - CEO</b>
                    <span>
                        Optimistic and emphatic. Committed to creating a strong
                        gastronomic community that allows people to connect with
                        others who share their same tastes and discover new
                        ones. Passionate about tennis and yoga.
                    </span>
                </li>
                <li className={styles.memberItem}>
                    <div className={styles.memberImageContainer}>
                        <img src={RebeImage} alt="coo-picture" />
                    </div>
                    <b>Rebeca Aguilar - COO</b>
                    <span>
                        Determined and clear. Devoted to overseeing and working
                        with all essential tasks to transform Cappacho into the
                        vibrant community we aspire to be. Children and Pilates
                        lover.
                    </span>
                </li>
                <li className={styles.memberItem}>
                    <div className={styles.memberImageContainer}>
                        <img src={GuttyImage} alt="cto-picture" />
                    </div>
                    <b>Gustavo Mora - CTO</b>
                    <span>
                        Wise and perfectionist. Focused on ensuring Cappacho's
                        great functionality by developing important systems and
                        bring technological advancements. Video games and Ping
                        Pong fan.
                    </span>
                </li>
            </ul>
        </section>
    );
};

export default OurTeam;
