import styles from './account-deactivation.module.scss';

const AccountDeactivationPage = () => (
    <main id={styles.accountDeactivation}>
        <h2 className={styles.header}>Deactivate your account</h2>
        <p>
            <h5>About deactivating your Cappacho account</h5>
            You can delete your account at Cappacho from Cappacho app.
            <br />
            <br />
            Once deleted, you will not be able to reactivate your existing
            account. You will need to create a new Cappacho account should you
            decide to begin using Cappacho again.
            <br />
            <br />
            When you are ready to delete your account, proceed with the
            following steps:
            <ul>
                <li>
                    Open Cappacho mobile application and sign in to your
                    account.
                </li>
                <li>
                    Go to your profile&nbsp;{'>'}&nbsp;<b>Settings</b>
                    &nbsp;{'>'}&nbsp;
                    <b>Delete account</b>.
                </li>
                <li>
                    Select your reason for deletion and press <b>Confirm</b>
                </li>
            </ul>
        </p>
        <p>
            <h5>Contact Us</h5>
            If you have questions or concerns about this Account Deactivation
            Policy, please contact us at:
            <ul>
                <li>
                    Email:&nbsp;
                    <a href="mailto:support@cappacho.com">
                        support@cappacho.com
                    </a>
                </li>
            </ul>
        </p>
    </main>
);

export default AccountDeactivationPage;
