import { ClockIcon } from 'lucide-react';

import styles from './cookies-policy.module.scss';

const CookiesPolicyPage = () => (
    <main id={styles.cookiesPolicy}>
        <h2 className={styles.header}>Cookies Policy</h2>
        <label className={styles.lastUpdate}>
            <ClockIcon size={12} />
            Last Updated: December 19th, 2024
        </label>
        <p style={{ borderBottom: 'none' }}>
            Cappacho Corp ("Cappacho," "we," or "our") is committed to being
            transparent about how we use cookies on our website ("the Website").
            This Cookie Policy explains what cookies are, how we use them, and
            your choices regarding their use.
        </p>
        <div className={styles.callout}>
            Cookies are small text files that are stored on your device
            (computer, tablet, or smartphone) when you visit a website. They are
            widely used to enhance website functionality and improve user
            experience. Cookies may collect information such as your browsing
            behavior, device information, and preferences.
        </div>
        <p>
            <h5>Types of Cookies We Use</h5>
            We use only Google Analytics cookies on our Website. These cookies
            help us understand how visitors interact with our Website by
            collecting information such as:
            <ul>
                <li>The pages you visit</li>
                <li>The time spent on each page</li>
                <li>Your geographic location (approximate, not precise)</li>
                <li>The type of device and browser you are using</li>
            </ul>
            This data is aggregated and anonymized, meaning it cannot be used to
            identify you personally.
        </p>
        <p>
            <h5>Why We Use Cookies</h5>
            The Google Analytics cookies we use serve the following purposes:
            <ul>
                <li>To analyze website traffic and user behavior.</li>
                <li>
                    To improve the functionality and content of our Website.
                </li>
                <li>
                    To help us better understand our audience and their
                    interests.
                </li>
            </ul>
            We do not use cookies for advertising purposes or share cookie data
            with third parties.
        </p>
        <p>
            <h5>Managing Cookies</h5>
            When you visit our Website for the first time, you will see a cookie
            banner allowing you to consent to or reject the use of cookies. You
            can also manage cookies at any time by adjusting your browser
            settings. Most browsers allow you to:
            <ul>
                <li>Delete cookies</li>
                <li>Block cookies</li>
                <li>Set preferences for certain websites</li>
            </ul>
            Please note that disabling cookies may affect the functionality of
            the Website.
        </p>
        <p>
            <h5>Data Retention</h5>
            The cookies we use have the following durations:
            <ul>
                <li>
                    Session cookies: These expire when you close your browser.
                </li>
                <li>
                    Persistent cookies: These remain on your device for a set
                    period (determined by Google Analytics) unless deleted
                    manually.
                </li>
            </ul>
        </p>
        <p>
            <h5>Changes to This Cookie Policy</h5>
            We may update this Cookie Policy periodically to reflect changes in
            our practices or for legal, regulatory, or operational reasons. We
            recommend reviewing this page regularly to stay informed about how
            we use cookies.
        </p>
        <p>
            <h5>Contact Us</h5>
            If you have questions or concerns about this Cookie Policy, please
            contact us at:
            <ul>
                <li>
                    Email:&nbsp;
                    <a href="mailto:support@cappacho.com">
                        support@cappacho.com
                    </a>
                </li>
            </ul>
        </p>
        <p>
            Thank you for visiting our Website. Your trust is important to us.
        </p>
    </main>
);

export default CookiesPolicyPage;
