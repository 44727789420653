import { useNavigate } from 'react-router';

import Logo from '../../icons/logo/logo';
import Button from '../button/button';
import { useMediaQuery } from '../../hooks/media-query';

import styles from './main-nav.module.scss';
import { goToContactForm } from '../../utils/contact-form';

const MainNav = () => {
    const navigate = useNavigate();
    const matches = useMediaQuery('(max-width: 720px)');

    return (
        <nav className={styles.menu}>
            <Logo
                color="#f60d0d"
                scale={matches ? 0.85 : 1.25}
                onClick={() => navigate('/')}
            />
            <Button testID="mainNavButton" onClick={goToContactForm}>
                Join our community
            </Button>
        </nav>
    );
};

export default MainNav;
