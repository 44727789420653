import { Link } from 'react-router-dom';

import styles from './footer.module.scss';

const DestinationLinks = () => (
    <ul className={styles.destinationLinks}>
        <li>
            <Link to="/privacy-policy">Privacy policy</Link>
        </li>
        <li>
            <Link to="/cookies-policy">Cookies policy</Link>
        </li>
        <li>
            <Link to="/account-deactivation">Account deactivation</Link>
        </li>
    </ul>
);

export default DestinationLinks;
