import Button from '../../../../components/button/button';
import MockImage from '../../../../assets/img/mock.png';

import styles from './hero.module.scss';
import { goToContactForm } from '../../../../utils/contact-form';

const Hero = () => {
    return (
        <section className={styles.hero}>
            <div className={styles.content}>
                <h1>
                    A social media platform revolutionizing food review systems
                    in NYC
                </h1>
                <span>
                    Cappacho is your space to share everything about gastronomy.
                    From your favorite foods to the restaurants you love. Here,
                    you can connect with other <b>foodies.</b>
                </span>
                <div className={styles.buttonContainer}>
                    <Button onClick={goToContactForm} testID="heroButton">
                        Join our community
                    </Button>
                </div>
            </div>
            <div className={styles.heroImageContainer}>
                <img
                    className={styles.heroImage}
                    src={MockImage}
                    alt="mock"
                    width="320"
                />
            </div>
        </section>
    );
};

export default Hero;
