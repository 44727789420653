import { Route, Routes } from 'react-router';

import styles from './app.module.scss';
import HomePage from './pages/home/home.page';
import MainNav from './components/main-nav/main-nav';
import PrivacyPolicyPage from './pages/privacy-policy/privacy-policy.page';
import AccountDeactivationPage from './pages/account-deactivation/account-deactivation.page';
import CookiesPolicyPage from './pages/cookies-policy/cookies-policy.page';
import Footer from './components/footer/footer';

function App() {
    return (
        <main id={styles.app}>
            <MainNav />
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                <Route path="/cookies-policy" element={<CookiesPolicyPage />} />
                <Route
                    path="/account-deactivation"
                    element={<AccountDeactivationPage />}
                />
            </Routes>
            <Footer />
        </main>
    );
}

export default App;
